import React, { useState } from 'react';
import './css/ProductSearch.css'; // Custom CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { convertToGregorian, convertToSolar } from './DateConverter'; // Import the conversion function


const ProductSearch = () => {
  const [productCode, setProductCode] = useState('');
  const [productDetails, setProductDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);


  // Mouse enter/leave handlers for the search input
  const onMouseEnter_input = () => {
    const el = document.getElementById('search_input');
    el.classList.add('show');
  };

  const onMouseLeave_input = () => {
    const el = document.getElementById('search_input');
    el.classList.remove('show');
  };
  const handleSearch = async () => {
    
    setErrorMessage('');
    setShowErrorPopup(false);
    setProductDetails(null);
    if(!productCode || productCode.length < 5 ){
      setErrorMessage('محصول یافت نشد');
      setShowErrorPopup(true);
    } else {
    try {
      const response = await fetch('https://query.miladcoolingindustry.com/api/getProductDetails.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ product_code: productCode }),
      });
      const resdata = await response.json();
      if (response.ok) {
        setProductDetails(resdata.data);
        setShowPopup(true); // Show popup on successful search
      } else {
        setErrorMessage('محصول یافت نشد');
        setShowErrorPopup(true);
      }
    } catch (error) {
      setErrorMessage('محصول یافت نشد | خطای سیستمی 502');
      setShowErrorPopup(true);
    }
    }//else
  };

  const handlePrint = () => {
    const printContent = document.getElementById('printable-table');
    const newWindow = window.open('', '_blank');
    newWindow.document.write('<html><head><meta charset="utf-8" />');
    newWindow.document.write('<style> body{direction:rtl;text-align:right;background-color:white;font-size:23px;}</style>');
    newWindow.document.write('<title>استعلام اصالت برودتی میلاد</title></head><body>');
    newWindow.document.write('<div style="display: flex; flex-direction: row; align-items: center; justify-content: center;')
    newWindow.document.write('width:465px;border:2px solid black;padding:15px;margin-right:15%;">');
    newWindow.document.write(printContent.outerHTML);
    newWindow.document.write('</div>');
    newWindow.document.write('<div style="font-size: 15px;width:465px;padding:15px;margin-right:15%;border:1px solid black;margin-top:10px;"><h5 style="text-shadow: rgb(27, 74, 242) 2px 1px;">شرایط گارانتی </h5>');
    newWindow.document.write('<p>دستگاه های شامل گارانتی می بایست دارای شماره سریال و برچسب مخصوص باشند؛بدیهی است خدمات و گارانتی محصولات بر اساس شماره سریال دستگاه صورت می گیرد.</p>');
    newWindow.document.write('<p>جهت بهره مندی از خدمات و گارانتی محصولات؛ رعایت اصول و شرایط نصب صحیح دستگاه الزامی است و در غیر این صورت ضمن ابطال گارانتی؛ مجموعه فاقد هرگونه مسئولیتی می باشد .</p>');
    newWindow.document.write('<p>جهت بهره‌مندی از گارانتی؛ معایب دستگاه می بایست مورد تایید تکنسین های متخصص مجموعه باشد .</p>');
    newWindow.document.write('<p>پس از اتمام گارانتی تمامی خدمات با دریافت هزینه و طبق تعرفه مجموعه صورت می گیرد .</p></div>');
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    newWindow.print();
  };

  return (
    <div className="container" 
     onMouseEnter={onMouseEnter_input}  >
      
      <div className="searchBox">
     
        <div className="search-container">
          <input
            type="text"
            className="search_input"
            id="search_input"
            placeholder="شماره سریال محصول را وارد کنید"
            value={productCode}
            onChange={(e) => setProductCode(e.target.value)}
          />
          <FontAwesomeIcon
            id="searchicon"
            icon={faSearch}
            className="search__icon"
            onClick={handleSearch}
          />
        </div>
      </div>

      {/*errorMessage && <div style="display:none;">{errorMessage}</div> */}

          {/* Error Popup */}
      {showErrorPopup && (
        <div className="Errorpopup">
          <div className="Errorpopup-content">
            <h2>خطا</h2>
            <p >{errorMessage}</p>
            <div className="Error_popup-buttons">
              <button className="Error_close-btn" onClick={() => setShowErrorPopup(false)}>
                بستن
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Popup Panel #delete */ console.log(productDetails)}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-btn" onClick={() => setShowPopup(false)}>
              &times;
            </button>
            {productDetails && (
              <div >
                <div id="printable-table">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>کد مشتری</td>
                        <td>{productDetails.customer_code}</td>
                      </tr>
                      <tr>
                        <td>نام مشتری</td>
                        <td>{productDetails.customer_name}</td>
                      </tr>
                      <tr>
                        <td>کد محصول</td>
                        <td>{productDetails.product_code}</td>
                      </tr>
                      <tr>
                        <td>نام محصول</td>
                        <td>{productDetails.product_name}</td>
                      </tr>
                      <tr>
                        <td> کمپرسور</td>
                        <td>{productDetails.compType}</td>
                      </tr>
                      <tr>
                        <td>گاز مبرد</td>
                        <td>{productDetails.gasMB}</td>
                      </tr>
                      <tr>
                        <td>سیستم سرمایش</td>
                        <td>{productDetails.coolingSys}</td>
                      </tr>
                      <tr>
                        <td>پذیرش گارانتی</td>
                        <td>{productDetails.garantAcc}</td>
                      </tr>
                      <tr>
                        <td>سریال محصول</td>
                        <td>{productDetails.product_serial}</td>
                      </tr>
                      <tr>
                        <td>تاریخ خرید</td>
                        <td>
                          {productDetails.date_buy_product
                        ? convertToSolar(productDetails.date_buy_product)
                        : 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>نوع خرید محصول</td>
                        <td>{productDetails.type_buy_product}</td>
                      </tr>
                      <tr>
                        <td>تاریخ اتمام گارانتی</td>
                        <td>
                          {productDetails.expire_date_granty
                            ? convertToSolar(productDetails.expire_date_granty)
                            : 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>نام مسئول گارانتی</td>
                        <td>{productDetails.name_employee_garanty}</td>
                      </tr>
                      <tr>
                        <td>توضیحات</td>
                        <td>{productDetails.special_description_garanty}</td>
                      </tr>
                      <tr>
                        <td>خدمات انجام شده به این محصول</td>
                        <td>{productDetails.description_service}</td>
                      </tr>
                     {/* <tr>
                        <td>Created At</td>
                        <td>{productDetails.created_at}</td>
                      </tr>*/}
                    </tbody>
                  </table>
                </div>
                <button className="printButton" onClick={handlePrint}>
                  چاپ
                </button>
                <button className="second_closeButton" onClick={() => setShowPopup(false)}>
                  بستن
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductSearch;
